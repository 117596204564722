import React, { useState } from 'react';
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Magnifier from '../assets/magnifier.inline.svg';
import DogBreed from './../components/atoms/DogBreed';

const Wrapper = styled.section`
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-left: 20rem;
    width: 50vw;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    margin-left: 46.6rem;
  }
`;

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.font.color.main};
  font-family: ${({ theme }) => theme.font.family.main};
  font-size: ${({ theme }) => theme.font.size.normal};
  margin: 2.5rem 0 1.5rem 0;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    margin: 10rem 0 1.5rem 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.font.size.bigger};
    margin: 8.5rem 0 1.8rem 0;
    text-align: left;
  }
`;

const StyledSearchWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    justify-content: flex-start;
  }
`;

const StyledSearchBox = styled.input`
  border-bottom: 1px solid ${({ theme }) => theme.font.placeholder};
  border: none;
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-size: ${({ theme }) => theme.font.size.big};
  height: 4.7rem;
  width: 27.7rem;
  ::placeholder {
    color: ${({ theme }) => theme.font.placeholder};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.font.size.superbig};
  }
`;

const StyledMagnifier = styled(Magnifier)`
  height: 2.1rem;
  margin-right: 1rem;
  width: 2.1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    height: 2.9rem;
    width: 2.9rem;
  }
`;

const StyledBreedList = styled.ul<{ open: boolean }>`
  height: ${({ open }) => (open ? '12rem' : '0')};
  list-style: none;
  transition: 0.2s;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    text-align: center;
    height: ${({ open }) => (open ? '16rem' : '0')};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    text-align: left;
  }
`;

const StyledSideTitle = styled.div`
  color: ${({ theme }) => theme.font.color.main};
  font-family: ${({ theme }) => theme.font.family.main};
  font-size: ${({ theme }) => theme.font.size.normal};
  font-weight: 600;
  line-height: 2.2rem;
  margin: 7rem 2.8rem 2.5rem;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 7.5rem 0 1.8rem;
    font-size: ${({ theme }) => theme.font.size.bigger};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    text-align: left;
  }
`;

const StyledSideDescription = styled.div`
  font-family: ${({ theme }) => theme.font.family.main};
  color: ${({ theme }) => theme.font.color.main};
  font-size: ${({ theme }) => theme.font.size.normal};
  font-weight: 300;
  line-height: 2.2rem;
  margin: 0 2.8rem 8rem;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    margin: 0 7rem 8rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    text-align: left;
    margin-left: 0;
    max-width: 71rem;
    font-size: ${({ theme }) => theme.font.size.bigger};
  }
`;

const StyledContactLink = styled(Link)`
  color: ${({ theme }) => theme.font.color.accent};
  font-family: ${({ theme }) => theme.font.family.main};
  font-size: ${({ theme }) => theme.font.size.normal};
  font-weight: 600;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 7.5rem 0 1.8rem;
    font-size: ${({ theme }) => theme.font.size.bigger};
  }
`;

const query = graphql`
  {
    datoCmsPrijslijst {
      mainTitle
      lijst
      sideTitle
      sideDescription
    }
  }
`;

const PrijsLijst = () => {
  const [searchedText, setSearchedText] = useState<string>('');
  const data = useStaticQuery(query);

  const {
    mainTitle,
    lijst,
    sideTitle,
    sideDescription,
  } = data.datoCmsPrijslijst;

  const splitedList = lijst.split('#');
  const filteredDogs = splitedList.filter((dog: string) =>
    dog.toLowerCase().includes(`${searchedText || '$'}`.toLowerCase())
  );

  const textSplitter = (txt: string) => {
    const contactLink = (
      <StyledContactLink to="/contact">contact</StyledContactLink>
    );
    const splitedTxt = txt.split('contact');
    return (
      <>
        {splitedTxt[0]}
        {contactLink}
        {splitedTxt[1]}
      </>
    );
  };

  return (
    <Wrapper>
      <StyledTitle>{mainTitle}</StyledTitle>
      <StyledSearchWrapper>
        <StyledMagnifier />
        <StyledSearchBox
          onChange={e => setSearchedText(e.target.value)}
          placeholder="Naam van het ras"
        />
      </StyledSearchWrapper>
      <StyledBreedList open={filteredDogs.length ? true : false}>
        <DogBreed dogs={filteredDogs} />
      </StyledBreedList>
      <StyledSideTitle>{textSplitter(sideTitle)}</StyledSideTitle>
      <StyledSideDescription>{sideDescription}</StyledSideDescription>
    </Wrapper>
  );
};

export default PrijsLijst;
