import React from 'react';
import styled from 'styled-components';

const StyledDogBreed = styled.li`
  font-family: ${({ theme }) => theme.font.family.main};
  font-size: ${({ theme }) => theme.font.size.normal};
  margin: 1rem 5.1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 1.8rem 0;
    font-size: ${({ theme }) => theme.font.size.bigger};
  }
`;

interface Props {
  dogs: [];
}

const DogBreed: React.FC<Props> = ({ dogs }) => {
  return (
    <>
      {dogs.slice(0, 5).map((dog: string) => (
        <StyledDogBreed key={dog}>{dog}</StyledDogBreed>
      ))}
    </>
  );
};

export default DogBreed;
